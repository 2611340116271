.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: var(--dsm-spacing-rem-10);
  border-radius: var(--dsm-spacing-rem-5);
  background-color: var(--dsm-color-neutral-lighter);

  dsm-icon {
    width: var(--dsm-spacing-rem-6);
    height: var(--dsm-spacing-rem-6);
    margin-left: var(--dsm-spacing-px-2);
  }
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: var(--dsm-spacing-rem-10);
  margin-left: var(--dsm-spacing-px-2);
  padding-right: var(--dsm-spacing-px-4);
  border: none;
  border-radius: 0 var(--dsm-spacing-rem-5) var(--dsm-spacing-rem-5) 0;
  outline: none;
  background-color: transparent;
  font: inherit;
  font-size: 0.875rem;

  &::placeholder {
    color: var(--dsm-color-neutral-darker);
  }
}
