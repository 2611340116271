.disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: normal;

  b {
    font-weight: 500;
    color: var(--dsm-color-basic-black);
    line-height: normal;
  }

  span {
    text-align: justify;
  }
}