@import "../../config.scss";

.container {
  display: inline-block;
  white-space: nowrap;
  padding: 2px 8px;
  align-items: center;
  border-radius: 16px;
  background: #f0f0f0;
  color: #000;
  font-size: 12px;
  line-height: 16px;
  font-family: 'DM Sans', sans-serif;
  height: fit-content;
}
