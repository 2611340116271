.use-guide {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;

  &__description{
    display: grid;
    grid-template-columns: 68% 32%;
    gap: 80px;
    margin-bottom: var(--dsm-spacing-px-4);
    
    video {
      width: 100%;
    }
    
  }

}