$dsm-hero-color: #44b4a1;

//Breakpoints
$dsm-breakpoint-sm: 640px;
$dsm-breakpoint-md: 768px;
$dsm-breakpoint-lg: 1024px;
$dsm-breakpoint-xl: 1280px;

$dsm-breakpoint-sm-max: 639px;
$dsm-breakpoint-md-max: 767px;
$dsm-breakpoint-lg-max: 1023px;
$dsm-breakpoint-xl-max: 1279px;
