@import "../../config.scss";

.result-list {
  @media print {
    border: 1px solid var(--dsm-color-neutral);
    border-radius: var(--dsm-spacing-px-2);
  }
}

.bar {
  display: flex;
  margin-bottom: var(--dsm-spacing-px-2);
  overflow: hidden;
  gap: var(--dsm-spacing-px-1);
  border-radius: var(--dsm-spacing-px-2);

  @media print {
    border-radius: var(--dsm-spacing-px-2) var(--dsm-spacing-px-2) 0 0;
  }

  &__item {
    flex-grow: 1;
    padding: var(--dsm-spacing-px-5) 0;
    padding: 0 var(--dsm-spacing-px-2);
    text-align: center;

    &--optimum {
      background-color: #76d0c2; //TODO use color variable
    }

    &--adequate {
      background-color: #c3e6e1; //TODO use color variable
    }

    &--insufficient {
      background-color: #ffaa4d; //TODO use color variable
    }

    &--deficient {
      background-color: #e67f7c; //TODO use color variable
    }
  }

  &__level {
    font-weight: 500;
    font-size: 1rem;
    line-height: 175%;
    white-space: nowrap;
  }

  &__range {
    margin-top: var(--dsm-spacing-px-2-minus);
    font-size: 0.75rem;
    white-space: nowrap;
  }

  &__value {
    span:nth-child(1) {
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 140%;
      letter-spacing: -0.02em;
    }

    span:not(:nth-child(1)) {
      margin-left: 2px;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
}

.list {
  background-color: var(--dsm-color-basic-white);

  &__level {
    display: flex;
    align-items: center;
    padding: var(--dsm-spacing-px-3);
    gap: var(--dsm-spacing-px-4);
    white-space: pre-wrap;

    &--optimum {
      &::before {
        background-color: #76d0c2; //TODO use color variable
      }
    }

    &--adequate {
      &::before {
        background-color: #c3e6e1; //TODO use color variable
      }
    }

    &--insufficient {
      &::before {
        background-color: #ffaa4d; //TODO use color variable
      }
    }

    &--deficient {
      &::before {
        background-color: #e67f7c; //TODO use color variable
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }

    &::before {
      display: inline-block;
      flex-shrink: 0;
      width: var(--dsm-spacing-px-4);
      height: var(--dsm-spacing-px-4);
      content: "";
    }

    b {
      font-weight: 700;
    }
  }
}
