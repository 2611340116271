@import "../../../../../config.scss";

.alert {
  margin-bottom: var(--dsm-spacing-px-10);
}

.cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--dsm-spacing-px-8);

  @media screen and (min-width: $dsm-breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.kits-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--dsm-spacing-px-5);
  @media screen and (min-width: $dsm-breakpoint-md) {
    flex-direction: row;
    align-items: flex-start;
  }

  img {
    width: var(--dsm-spacing-px-24);
    height: var(--dsm-spacing-px-24);
    border-radius: var(--dsm-spacing-px-2);
  }
}

.budget-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--dsm-spacing-px-5);
  @media screen and (min-width: $dsm-breakpoint-md) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.stepper-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.card-sub-header {
  display: flex;
  margin-top: var(--dsm-spacing-px-4);
  color: var(--dsm-color-neutral-darker);
  font-weight: 500;
}

.currency {
  margin-top: var(--dsm-spacing-px-2);
  font-weight: 500;
  font-size: 2.125rem;
  letter-spacing: -0.02rem;
}

.loader {
  display: flex;
  justify-content: center;
}
