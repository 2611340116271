.header-menu {
  position: relative;

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dsm-spacing-px-10);
    height: var(--dsm-spacing-px-10);
    border-radius: 50%;
    background-color: var(--dsm-color-neutral-lighter);
    font-weight: 500;
    cursor: pointer;
  }

  &__dropdown {
    display: none;
    z-index: var(--dsm-z-index-second);
    position: absolute;
    top: calc(var(--dsm-spacing-px-10) + var(--dsm-spacing-px-05));
    right: 0;
    border: 1px solid var(--dsm-color-neutral);
    border-radius: var(--dsm-spacing-px-1);
    background: var(--dsm-color-basic-white);

    &--open {
      display: block;
    }
  }

  &__dropdown-header {
    display: flex;
    align-items: center;
    padding: var(--dsm-spacing-px-4) var(--dsm-spacing-px-4) var(--dsm-spacing-px-2) var(--dsm-spacing-px-4);
    font-weight: 500;

    span {
      display: flex;
      position: relative;
      justify-content: center;
      width: var(--dsm-spacing-px-4);
      height: var(--dsm-spacing-px-4);
      margin-right: var(--dsm-spacing-px-2);
      border-radius: 50%;
      background-color: var(--dsm-color-neutral-lighter);
      color: var(--dsm-color-neutral-darker);
      font-size: 0.75rem;
      line-height: var(--dsm-spacing-px-4);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: var(--dsm-spacing-px-4);
    border-bottom: 1px solid var(--dsm-color-neutral);
    font-weight: 500;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    dsm-icon {
      width: var(--dsm-spacing-px-4);
      margin-right: var(--dsm-spacing-px-2);
    }
  }
}
