@import "../../../config.scss";

.hero {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 175px;
  margin-bottom: var(--dsm-spacing-px-5);
  background-color: $dsm-hero-color;
  white-space: pre-line;

  @media screen and (min-width: $dsm-breakpoint-sm) {
    &::before {
      position: absolute;
      right: var(--dsm-spacing-px-5-minus);
      width: 180px;
      height: 180px;
      background-image: url("/assets/images/hero-background-after.svg");
      content: "";
    }
  }

  @media screen and (min-width: $dsm-breakpoint-sm) {
    height: var(--dsm-spacing-px-56);
  }

  &__content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &::before {
      position: absolute;
      left: var(--dsm-spacing-px-16-minus);
      width: 328px;
      height: 180px;
      transform: scale(0.8);
      background-image: url("/assets/images/hero-background-before.svg");
      content: "";

      @media screen and (min-width: $dsm-breakpoint-sm) {
        transform: scale(1);
      }
    }
  }

  &__header {
    z-index: 1;
    max-width: 750px;
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5rem;

    @media screen and (min-width: $dsm-breakpoint-sm) {
      font-size: 1.75rem;
      line-height: 2rem;
    }
  }

  &__description {
    display: none;
    z-index: 1;
    margin-top: var(--dsm-spacing-px-1);

    @media screen and (min-width: $dsm-breakpoint-sm) {
      display: block;
    }
  }
}

.tables {
  display: flex;
  flex-direction: column;
  margin-top: var(--dsm-spacing-px-5);
  gap: var(--dsm-spacing-px-5);

  @media screen and (min-width: $dsm-breakpoint-sm) {
    margin-top: var(--dsm-spacing-px-10);
    gap: var(--dsm-spacing-px-8);
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 var(--dsm-spacing-px-3) 0;

    h3 {
      margin: 0;
    }

    a {
      display: flex;
      align-items: center;
      gap: 2px;
      color: var(--dsm-color-basic-black);
      font-weight: 700;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    dsm-icon {
      width: var(--dsm-spacing-rem-5);
    }
  }

  &__table {
    flex-grow: 1;

    dsm-table {
      border: 1px solid var(--dsm-color-neutral-lighter);
      border-bottom: none;
      border-radius: var(--dsm-spacing-px-1);
    }

    dsm-loading-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 238px; //Based on table height with content
      border: 1px solid var(--dsm-color-neutral-lighter);
      border-radius: var(--dsm-spacing-px-1);
    }

    dsm-empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 238px; //Based on table height with content
      border: 1px solid var(--dsm-color-neutral-lighter);
      border-radius: var(--dsm-spacing-px-1);
    }
  }
}
