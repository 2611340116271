@import "../../../../../config.scss";

.create-farm {
  margin-top: var(--dsm-spacing-px-2);
}

.loader {
  display: flex;
  justify-content: center;
}

.form-order-kit {
  display: flex;
  flex-direction: column;
  gap: var(--dsm-spacing-px-4);

  @media screen and (min-width: $dsm-breakpoint-sm) {
    flex-direction: row;
    gap: unset;
  }

  &__header {
    margin: var(--dsm-spacing-px-8) 0 0 0;
    color: var(--dsm-color-blue);
  }

  &__part {
    display: flex;
    flex-direction: column;
    gap: var(--dsm-spacing-px-4);

    @media screen and (min-width: $dsm-breakpoint-sm) {
      width: 50%;

      &:first-of-type {
        padding-right: var(--dsm-spacing-px-10);
      }

      &:last-of-type {
        padding-left: var(--dsm-spacing-px-10);
        border-left: 1px solid var(--dsm-color-neutral-darker);
      }
    }
  }

  &__unit-row {
    display: grid;
    grid-template-columns: 76% 23%;
    grid-gap: 1%;

    dsm-input {
      flex-grow: 1;
    }

    dsm-select {
      width: var(--dsm-spacing-px-20);
      margin-top: calc(var(--dsm-spacing-rem-6) + var(--dsm-spacing-px-1));
    }
  }
}