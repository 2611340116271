@import "../../../config.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: var(--dsm-spacing-px-4);

  @media screen and (min-width: $dsm-breakpoint-md) {
    width: 50%;
  }
}

.actions {
  display: flex;
  align-items: center;
  margin-top: var(--dsm-spacing-px-10);
  gap: var(--dsm-spacing-px-4);
}

.success {
  text-align: center;
  white-space: break-spaces;
}
