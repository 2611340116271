.container {
  display: flex;
  gap: var(--dsm-spacing-px-2);

  dsm-loading-indicator {
    margin-top: calc(var(--dsm-spacing-rem-6) + var(--dsm-spacing-px-1));
  }

  dsm-select {
    flex: 1;
  }
}

.loader {
  display: flex;
  align-items: center;
  height: var(--dsm-spacing-rem-10);
}

dsm-select {
  max-width: 100%;
}

.no-option-text {
  margin-top: var(--dsm-spacing-px-2-minus);
  color: var(--dsm-color-neutral-darker);
}
