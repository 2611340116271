@import "../../config.scss";

.notifications {
  width: 480px;

  @media screen and (max-width: $dsm-breakpoint-sm) {
    display: flex;
    z-index: var(--dsm-z-index-first);
    position: fixed;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - var(--dsm-spacing-rem-16));
    background-color: var(--dsm-color-basic-white);
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: var(--dsm-spacing-px-5);
    gap: var(--dsm-spacing-px-3);

    @media screen and (max-width: $dsm-breakpoint-sm) {
      display: none;
    }

    dsm-icon {
      width: var(--dsm-spacing-px-5);
      height: var(--dsm-spacing-px-5);
      padding: var(--dsm-spacing-rem-3);
      border-radius: 50%;
      background-color: var(--dsm-color-primary-lightest);
      color: var(--dsm-color-primary);
    }

    span {
      color: var(--dsm-color-primary);
      font-weight: 500;
    }
  }

  &__header-mobile {
    display: flex;
    margin: var(--dsm-spacing-px-4);
    padding-right: var(--dsm-spacing-rem-6);

    @media screen and (min-width: $dsm-breakpoint-sm) {
      display: none;
    }

    span {
      margin: auto;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.25rem;
    }
  }

  &__back {
    margin: auto var(--dsm-spacing-rem-4) var(--dsm-spacing-rem-2) var(--dsm-spacing-rem-4);

    @media screen and (min-width: $dsm-breakpoint-sm) {
      display: none;
    }
  }
}

.notification {
  display: flex;
  padding: var(--dsm-spacing-px-3) var(--dsm-spacing-px-4);
  gap: var(--dsm-spacing-px-4);
  background-color: var(--dsm-color-primary-lightest);
  font-size: 0.875rem;
  cursor: pointer;

  @media screen and (max-width: $dsm-breakpoint-sm) {
    justify-content: space-between;
    border-bottom: 1px solid var(--dsm-color-neutral);
  }
  @media screen and (min-width: $dsm-breakpoint-sm) {
    border-top: 1px solid var(--dsm-color-neutral);
  }

  &__content {
    flex-grow: 1;
  }

  &__header {
    font-weight: 500;
  }

  &__chevron {
    align-self: center;
  }

  dsm-icon {
    margin-top: var(--dsm-spacing-px-1);
    color: var(--dsm-color-primary);
  }
}

.description {
  display: flex;
  justify-content: center;
  padding: var(--dsm-spacing-px-4);
  gap: var(--dsm-spacing-px-4);
  font-style: italic;
  font-size: 0.875rem;

  @media screen and (min-width: $dsm-breakpoint-sm) {
    border-top: 1px solid var(--dsm-color-neutral);
  }
}
