@import "../../../config.scss";

.table {
  margin-top: var(--dsm-spacing-px-5);
  margin-right: var(--dsm-spacing-px-4-minus);
  margin-left: var(--dsm-spacing-px-4-minus);

  @media screen and (min-width: $dsm-breakpoint-sm) {
    margin-right: unset;
    margin-left: unset;
    border: 1px solid var(--dsm-color-neutral-lighter);
    border-radius: var(--dsm-spacing-px-1);
  }

  dsm-table {
    margin-bottom: -1px;
  }

  dsm-empty-state {
    margin-top: var(--dsm-spacing-px-32);
    margin-bottom: var(--dsm-spacing-px-32);
  }

  dsm-loading-indicator {
    display: flex;
    justify-content: center;
    margin-top: var(--dsm-spacing-px-32);
    margin-bottom: var(--dsm-spacing-px-32);
  }
}
