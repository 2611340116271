@import "../../config.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--dsm-spacing-px-5);
  gap: var(--dsm-spacing-px-3);

  @media screen and (min-width: $dsm-breakpoint-md) {
    flex-direction: row;
    gap: var(--dsm-spacing-px-6);
  }

  @media print {
    width: 100vw;
    margin: var(--dsm-spacing-px-1-minus) 0 var(--dsm-spacing-px-4) var(--dsm-spacing-px-8-minus);
    padding: calc(var(--dsm-spacing-px-4) + var(--dsm-spacing-px-1)) var(--dsm-spacing-px-8) var(--dsm-spacing-px-8) var(--dsm-spacing-px-8);
    background-color: #89c7e8;
    color: white;

    * {
      z-index: 1;
    }

    &::before {
      z-index: 0;
      position: absolute;
      top: 20px;
      left: -30px;
      width: 146px;
      height: 84px;
      background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 146 84" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="42" cy="42" r="42" fill="%23307197" /><circle cx="104" cy="42" r="42" fill="%23307197" /></svg>');
      content: "";
    }

    &::after {
      z-index: 0;
      position: absolute;
      top: 20px;
      right: -30px;
      width: 84px;
      height: 84px;
      background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="42" cy="42" r="42" fill="%23307197" /></svg>');
      content: "";
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--dsm-spacing-px-4);
}

@media print {
 .title__total-items {
    display: none;
  }
}

.header {
  display: none;
  margin: 0;

  @media screen and (min-width: $dsm-breakpoint-sm) {
    display: block;
    padding: var(--dsm-spacing-px-2) 0;
  }

  @media print {
    display: block;
    padding: var(--dsm-spacing-px-3) 0 0 var(--dsm-spacing-px-1);
  }
}

.side {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-self: start;
  justify-content: flex-end;
  gap: var(--dsm-spacing-px-5);

  video {
    width: 350px;
  }
}

.content {
  margin-top: var(--dsm-spacing-px-2);
  white-space: pre-wrap;

  @media screen and (min-width: $dsm-breakpoint-sm) {
    margin-top: 0;
  }

  video {
    width: 100%;
    margin-top: var(--dsm-spacing-px-3);
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--dsm-spacing-px-2);

  @media print {
    display: none;
  }

  &--description {
    margin-top: var(--dsm-spacing-px-3);

    @media screen and (min-width: $dsm-breakpoint-md) {
      margin-top: var(--dsm-spacing-px-5);
    }
  }

  dsm-button {
    flex-shrink: 0;
  }
}

video {
  border-radius: var(--dsm-spacing-px-1);
}
