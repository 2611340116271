.user-guide__details {
  display: flex;
  flex-direction: column;

  &-header {
    margin: 24px 0px 16px 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

  &-items {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
    padding-left: 24px;
  }
}