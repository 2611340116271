.stats-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--dsm-color-neutral-lighter);
  border-radius: 4px;
  color: var(--dsm-color-font);
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-family: 'DSM Sans', sans-serif;
  margin: 0;
  padding: 0;
  border-spacing: 0;

  &__head {
    th {
      border: none;
      border-right: 1px solid var(--dsm-color-neutral-lighter);
      border-bottom: 1px solid var(--dsm-color-neutral-lighter);
      padding: var(--dsm-spacing-px-3);
      max-width: 120px;
      min-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;

      &:nth-child(odd) {
        background-color: var(--dsm-color-neutral-lightest);
      }

      &:nth-child(even) {
        background-color: transparent;
      }
    }

    th:first-child, tr:first-child {
      position: sticky;
      z-index: 2;
      left: 0;
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;

      &__color-dot {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        flex-shrink: 0;
      }

      &__name {
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        display: inline-block;
      }
    }
  }

  &__body {
    tr {
      td {
        padding: var(--dsm-spacing-px-3);
        border: none;
        border-right: 1px solid var(--dsm-color-neutral-lighter);
        max-width: 120px;
        min-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:nth-child(odd) {
          background-color: var(--dsm-color-neutral-lightest);
        }

        &:nth-child(even) {
          background-color: transparent;
        }

      }
    }
    tr:first-child, td:first-child {
      position: sticky;
      z-index: 2;
      left: 0;
    }

    &__data-cell {
      font-weight: normal;
    }
  }
}

@media print {
  .stats-table {
    width: 100%; 
    font-size: 10px; 
    page-break-before: avoid;
    page-break-after: avoid; 
    page-break-inside: avoid;
  }

  .stats-table th,
  .stats-table td {
    min-width: auto !important;
    max-width: none;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .stats-table__head th:first-child,
  .stats-table__body td:first-child {
    position: static;
  }
}

