@import "../../config.scss";

.table-details {
  margin-right: var(--dsm-spacing-px-4-minus);
  margin-left: var(--dsm-spacing-px-4-minus);

  @media screen and (min-width: $dsm-breakpoint-sm) {
    margin-right: unset;
    margin-left: unset;
    border: 1px solid var(--dsm-color-neutral-lighter);
    border-radius: var(--dsm-spacing-px-1);
  }

  dsm-table {
    margin-bottom: -1px;
  }

  dsm-empty-state {
    margin-top: var(--dsm-spacing-px-32);
    margin-bottom: var(--dsm-spacing-px-32);
  }

  dsm-loading-indicator {
    display: flex;
    justify-content: center;
    margin-top: var(--dsm-spacing-px-32);
    margin-bottom: var(--dsm-spacing-px-32);
  }
}

.custom-details__actions {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  margin-top: -16px;

  &-row{
    display: flex;
    gap: 2px;
  }
}
