@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

.swiper-pagination {
  bottom: 10px !important;
}

@page {
  counter-increment: page;
  @bottom-right {
    content: "Page " counter(page) " of " counter(pages);
    font-size: 12px;
    margin-bottom: var(--dsm-spacing-px-24);
    margin-right: var(--dsm-spacing-px-6);
  };
}

.swiper-pagination-bullet {
  background: #D9D9D9 !important;
  opacity: 0.6;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
  background: black !important;
  opacity: 1;
  width: 12px;
  height: 12px;
}

.print-break-before {
  break-before: always;
}

.panel {
  padding: var(--dsm-spacing-px-6) var(--dsm-spacing-px-5);
  border-radius: var(--dsm-spacing-px-2);
  background-color: #f7f7f7;

  @media print {
    padding: 0;
    border-radius: none;
    background-color: transparent;
  }

  h3 {
    margin-bottom: var(--dsm-spacing-px-4);
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 160%;
  }
}

.grid-refresh {
  position: relative;

  &::before {
    z-index: 900;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--dsm-spacing-px-2);
    background-color: var(--dsm-color-basic-black);
    content: "";
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    user-select: none;
  }

  &__loader {
    display: none;
    position: absolute;
    top: calc(50% - var(--dsm-spacing-px-4));
    left: calc(50% - var(--dsm-spacing-px-4));
  }

  &__refreshing {
    &::before {
      opacity: 0.3;
      pointer-events: all;
    }
    .grid-refresh__loader {
      display: block;
    }
  }
}

.value-card {
  padding: var(--dsm-spacing-px-6);
  border-radius: var(--dsm-spacing-px-2);
  background-color: var(--dsm-color-basic-white);

  @media print {
    border: 1px solid var(--dsm-color-neutral);
  }

  h5 {
    margin-bottom: var(--dsm-spacing-px-3);
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  &__value {
    span:nth-child(1) {
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 140%;
      letter-spacing: -0.02em;
    }

    span:not(:nth-child(1)) {
      margin-left: 2px;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
}

.scatter {
  .main-svg {
    background: none !important;
  }

  .hovertext {
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.15));
  }
}

a:visited {
  color: currentColor;
}

.filters {
  display: flex;
  position: relative;
  margin-bottom: var(--dsm-spacing-px-5);
  gap: var(--dsm-spacing-px-2);

  .filter-container {
    margin-left: auto;
  }
}

.search {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  gap: var(--dsm-spacing-px-4);
  align-items: center;
}

.filter-row {
  &__two-items {
    display: flex;
    gap: var(--dsm-spacing-px-2);

    * {
      flex: 1;
    }
  }
}
