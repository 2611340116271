@import "../../config.scss";

.graph-panel {
  position: relative;
  margin-bottom: var(--dsm-spacing-px-5);
  page-break-after: always;
  page-break-inside: avoid;
}

.selected-samples-header {
  display: flex;
  justify-content: space-between;
  margin-top: var(--dsm-spacing-px-5);

  @media print{
    display: none;
  }
}

.selected-samples {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--dsm-spacing-px-5);
  gap: var(--dsm-spacing-px-2);

  @media print{
    display: none;
  }
}

@media print{
  .box-plot {
    align-items: center;
    page-break-inside: avoid;
    &::before {
      max-height: 415px;
    }

    &::after {
      max-height: fit-content;
    }
  }
}

.box-plot {
  position: relative;

  &::before {
    z-index: 1;
    position: absolute;
    top: 44px;
    width: var(--dsm-spacing-px-1);
    height: 445px;
    margin-left: 70px;
    content: "";
  }

  &__empty {
    &::after {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--dsm-color-basic-black);
      content: "";
      opacity: 0.15;
    }
  }

  &__empty-text {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 1.3rem;
    text-align: center;
  }
}

.stats {
  display: flex; 
  overflow-x: auto; 
  white-space: nowrap;
  padding: 0px;
  margin-top: var(--dsm-spacing-px-5);
  margin-bottom: 0;
  page-break-inside: avoid;

  @media print {
    display: block;
    overflow-x: visible;
    white-space: normal;
    page-break-inside: avoid;
    page-break-after: avoid;
    margin: 0;
    margin-top: 60px;
    height: fit-content;
    margin-bottom: 20%;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  &-buttons {
    display: flex;
    gap: var(--dsm-spacing-px-2);
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;

    @media print{
      display: none;
    }
  }
}

.print-footer {
  display: block;

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--dsm-spacing-px-2);
  }

  @media print {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: var(--dsm-spacing-px-8);
    justify-content: space-between;
    width: calc(100% - var(--dsm-spacing-px-16));
    img {
      height: var(--dsm-spacing-px-4);
    }
    page-break-after: avoid;
    page-break-before: avoid;
  }

  @media screen and (min-width: $dsm-breakpoint-lg) {
    display: none;
  }
}
