@import "../../config.scss";

.modal {
  &__content {
    @media screen and (min-width: $dsm-breakpoint-md) {
      width: 600px;
    }
  }

  &__video {
    display: flex;
    position: relative;

    video {
      aspect-ratio: 16 / 9;
      width: 100%;
      border-radius: var(--dsm-spacing-px-1);
      background-color: var(--dsm-color-basic-black);
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--dsm-color-basic-white);
    }

    svg {
      stroke: var(--dsm-color-success) !important;
    }
  }

  &__codes-header {
    margin-top: var(--dsm-spacing-px-2);
  }

  &__codes {
    display: flex;
    gap: var(--dsm-spacing-px-1);
  }
}
