.user-guider__step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 44px;

  &-title {
    color: --var(--dsm-color-basic-black);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    text-decoration-skip-ink: none;
    margin-top: 16px;
  }

  &-description {
    color: --var(--dsm-color-font);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 12px;
    max-width: 136px;
  }

  &:last-child &-description {
      max-width: 204px;
  }   

}