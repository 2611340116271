@import "../../../config.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: var(--dsm-spacing-px-4);

  @media screen and (min-width: $dsm-breakpoint-sm) {
    display: flex;
    flex-direction: row;
    margin-top: var(--dsm-spacing-px-10);
    gap: unset;
  }

  &__part {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--dsm-spacing-px-4);
    gap: var(--dsm-spacing-px-4);

    @media screen and (min-width: $dsm-breakpoint-sm) {
      width: 50%;
      margin-bottom: 0;

      &:first-of-type {
        padding-right: var(--dsm-spacing-px-10);
        border-right: 1px solid var(--dsm-color-neutral-darker);
      }

      &:last-of-type {
        padding-left: var(--dsm-spacing-px-10);
      }
    }
  }
  &__header {
    margin: var(--dsm-spacing-px-4) 0 0 0;

    @media screen and (min-width: $dsm-breakpoint-sm) {
      margin: var(--dsm-spacing-px-8) 0 0 0;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  .form__row {
    display: flex;
    gap: var(--dsm-spacing-px-8);

    * {
      width: 50%;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  margin-top: var(--dsm-spacing-px-10);
  gap: var(--dsm-spacing-px-4);
}
