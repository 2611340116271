@import "../config.scss";

@media print {
  @page {
    margin: var(--dsm-spacing-px-4) 0 var(--dsm-spacing-px-4) 0;
  }

  @page :first {
    margin: 0 0 var(--dsm-spacing-px-4) 0;
  }
}

html,
body {
  overscroll-behavior: none;
  print-color-adjust: exact !important;
}

a[tabIndex] {
  cursor: pointer;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: calc(var(--dsm-spacing-px-3) + var(--dsm-spacing-px-16) + var(--dsm-spacing-px-05));
}

.main-container {
  @media screen and (min-width: $dsm-breakpoint-sm) {
    margin-top: var(--dsm-spacing-px-10);
  }

  &--with-breadcrumb {
    @media screen and (min-width: $dsm-breakpoint-sm) {
      margin-top: var(--dsm-spacing-px-5);
    }
  }
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: var(--dsm-spacing-px-5);
}

.card {
  padding: var(--dsm-spacing-px-6);
  border: 1px solid var(--dsm-color-neutral-lighter);
  border-radius: var(--dsm-spacing-px-1);

  &--table {
    padding: 0;

    dsm-table {
      margin-bottom: -1px;
    }

    dsm-pagination {
      border-bottom: 1px solid var(--dsm-color-neutral-lighter);
    }

    dsm-empty-state {
      margin: var(--dsm-spacing-px-4);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1rem;
  }
}

.sub-header {
  display: flex;
  align-items: center;
  margin-bottom: var(--dsm-spacing-px-4);
  gap: var(--dsm-spacing-px-2);

  h3 {
    margin: 0;
  }
}

.toast {
  position: fixed;
  bottom: var(--dsm-spacing-px-8);
  width: calc(100% - var(--dsm-spacing-px-16));
  margin: 0 var(--dsm-spacing-px-8);

  &__error {
    margin-top: var(--dsm-spacing-px-2-minus);
    font-style: italic;
    font-size: 0.75rem;
  }
}

.cookiepolicy {
  display: none;
}

//Special styling for print, hide unnecessary elements and spacing, set min-width to 1024px
@media print {
  dsm-header,
  dsm-breadcrumb,
  dsm-footer,
  dsm-footer-navigation,
  #mobile-header {
    display: none;
  }

  main {
    margin-right: var(--dsm-spacing-px-8);
    margin-left: var(--dsm-spacing-px-8);
  }

  .main-container {
    padding: 0;
  }

  body {
    min-width: 1024px;
  }

  dsm-table {
    border: 1px solid var(--dsm-color-neutral);
    border-radius: var(--dsm-spacing-px-2);
  }
}
