.tabs {
  margin-bottom: var(--dsm-spacing-px-4);
}

.files {
  display: flex;
  flex-direction: column;
  gap: var(--dsm-spacing-px-1);
}

.file {
  display: flex;
  align-items: center;
  gap: var(--dsm-spacing-px-2);

  dsm-icon {
    flex-shrink: 0;
    width: var(--dsm-spacing-px-5);
    height: var(--dsm-spacing-px-5);
  }

  a {
    color: var(--dsm-color-basic-black);
    word-break: break-all;
  }

  span {
    white-space: nowrap;
  }
}

.sub-header {
  margin-top: var(--dsm-spacing-px-8);

  &--first {
    margin-top: 0;
  }

  h3 {
    margin-bottom: 0;
  }

  span {
    margin-bottom: var(--dsm-spacing-px-3);
  }
}

ul {
  margin: 0;
}
