@import "../../../config.scss";

.tables {
  display: flex;
  margin-top: var(--dsm-spacing-px-5);
  gap: var(--dsm-spacing-px-5);

  @media screen and (min-width: $dsm-breakpoint-sm) {
    margin-top: var(--dsm-spacing-px-10);
    gap: var(--dsm-spacing-px-8);
  }

  @media screen and (min-width: $dsm-breakpoint-lg) {
    flex-direction: row;
  }

  &__table {
    width: 100%;

    h3 {
      margin: 0 0 var(--dsm-spacing-px-3) 0;
    }

    dsm-table {
      border: 1px solid var(--dsm-color-neutral-lighter);
      border-bottom: none;
      border-radius: var(--dsm-spacing-px-1);
    }

    dsm-loading-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 238px;
      border: 1px solid var(--dsm-color-neutral-lighter);
      border-radius: var(--dsm-spacing-px-1);
    }

    dsm-empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 238px;
      border: 1px solid var(--dsm-color-neutral-lighter);
      border-radius: var(--dsm-spacing-px-1);
    }
  }
}

.kitDetails:first-child {
  margin: var(--dsm-spacing-px-12);
  padding: var(--dsm-spacing-px-12);
  font-size: 1rem;
}
.kit {
  margin-bottom: var(--dsm-spacing-px-2);
  padding: var(--dsm-spacing-px-1);
  border: 1px solid var(--dsm-color-neutral-lighter);
}
.divider {
  margin-bottom: var(--dsm-spacing-px-4);
  border-bottom: 1px solid var(--dsm-color-neutral-lighter);
}

.orderStatus {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $dsm-breakpoint-lg) {
    flex-direction: row;
    margin-bottom: var(--dsm-spacing-px-4);
  }
}

.buttons {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  gap: var(--dsm-spacing-px-4);
}
