@import "../../../config.scss";

.result-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: var(--dsm-spacing-px-6);
  gap: var(--dsm-spacing-px-3);
}

.phase-filter {
  margin-bottom: var(--dsm-spacing-px-5);
}

.samples-table {
  margin-top: var(--dsm-spacing-px-5);
}
