@import "../../../config.scss";

.grid {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.progress {
  margin-bottom: var(--dsm-spacing-px-5);
  @media screen and (min-width: $dsm-breakpoint-md) {
    margin-bottom: var(--dsm-spacing-px-10);
  }
}

.step {
  display: none;

  &--active {
    display: block;
    flex-grow: 1;
  }
}

.progress-controls {
  display: flex;
  justify-content: space-between;
  margin-top: var(--dsm-spacing-px-10);
  gap: var(--dsm-spacing-px-4);
}

.loader {
  display: flex;
  justify-content: center;
}

.form-poultry {
  display: flex;
  flex-direction: column;
  gap: var(--dsm-spacing-px-4);

  @media screen and (min-width: $dsm-breakpoint-sm) {
    flex-direction: row;
    gap: unset;
  }

  &__header {
    margin: var(--dsm-spacing-px-8) 0 0 0;
    color: var(--dsm-color-blue);
  }

  &__part {
    display: flex;
    flex-direction: column;
    gap: var(--dsm-spacing-px-4);

    @media screen and (min-width: $dsm-breakpoint-sm) {
      width: 50%;

      &:first-of-type {
        padding-right: var(--dsm-spacing-px-10);
      }

      &:last-of-type {
        padding-left: var(--dsm-spacing-px-10);
        border-left: 1px solid var(--dsm-color-neutral-darker);
      }
    }
  }

  &__unit-row {
    display: grid;
    grid-template-columns: 76% 23%;
    grid-gap: 1%;

    dsm-input {
      flex-grow: 1;
    }

    dsm-select {
      width: var(--dsm-spacing-px-20);
      margin-top: calc(var(--dsm-spacing-rem-6) + var(--dsm-spacing-px-1));
    }
  }
}

.cards {
  margin-top: var(--dsm-spacing-px-10);

  &__row {
    display: flex;
    border-bottom: solid 1px var(--dsm-color-neutral);

    @media screen and (min-width: $dsm-breakpoint-md) {
      border-bottom: none;
    }
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: var(--dsm-spacing-px-2);
    gap: var(--dsm-spacing-px-2);

    &--error {
      align-items: flex-start;
    }

    dsm-input {
      flex-shrink: 0;
      width: var(--dsm-spacing-px-48);
    }

    dsm-radio-button-group {
      align-self: flex-start;
      max-width: var(--dsm-spacing-px-48);

      @media screen and (min-width: $dsm-breakpoint-lg) {
        max-width: unset;
      }
    }
  }

  &__delete-icon {
    flex-shrink: 0;
    margin-top: var(--dsm-spacing-rem-8);
    margin-left: var(--dsm-spacing-rem-2);
    color: var(--dsm-color-error);

    &:hover,
    &:focus-visible {
      color: var(--dsm-color-error-darkest);
    }
  }

  &__actions {
    display: flex;
    gap: var(--dsm-spacing-px-2);
  }
}

.form-error {
  color: var(--dsm-color-error);
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1rem;
}
