@import "../../../config.scss";

.grid {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.progress {
  margin-bottom: var(--dsm-spacing-px-5);
  @media screen and (min-width: $dsm-breakpoint-md) {
    margin-bottom: var(--dsm-spacing-px-10);
  }
}

.step {
  display: none;

  &--active {
    display: block;
    flex-grow: 1;
  }
}

.progress-controls {
  display: flex;
  justify-content: space-between;
  margin-top: var(--dsm-spacing-px-10);
  gap: var(--dsm-spacing-px-4);
}
