@import "../../config.scss";

.container {
  display: flex;
  align-items: center;
  margin: var(--dsm-spacing-px-4);
  gap: var(--dsm-spacing-px-4);
}

.title {
  display: inline-block;
  margin: auto;
  font-size: 1.5rem;
  line-height: 1.25rem;
}
