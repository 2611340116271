@import "../../../config.scss";

.box {
  display: none;
  z-index: 100;
  position: absolute;
  top: calc(var(--dsm-spacing-px-10) + var(--dsm-spacing-px-1));
  right: 0;
  flex-direction: column;
  width: calc(100% - var(--dsm-spacing-px-8));
  padding: var(--dsm-spacing-px-4);
  gap: var(--dsm-spacing-px-4);
  border: 1px solid var(--dsm-color-neutral);
  border-radius: var(--dsm-spacing-px-1);
  background-color: var(--dsm-color-basic-white);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.15);

  @media screen and (min-width: $dsm-breakpoint-sm) {
    width: 400px;
  }

  &--open {
    display: flex;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--dsm-spacing-px-4);
    gap: var(--dsm-spacing-px-2);
  }

  dsm-button:first-of-type {
    margin-right: auto;
  }
}
